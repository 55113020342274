import { graphql, useStaticQuery } from "gatsby";

const useCreateAccountData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Create Account Page" } }
      ) {
        nodes {
          heading
          contentModules {
            ... on ContentfulLink {
              linkText
              url
            }
            ... on ContentfulTitleDescription {
              title
              description {
                description
              }
              link {
                linkText
                url
              }
            }
          }
        }
      }
    }
  `);
  return data.allContentfulPageLayout.nodes[0];
};

export default useCreateAccountData;
