import React from "react";
import PropTypes from "prop-types";

const LinkArrowIcon = ({ width, height, marginLeft, className }) => {
  return (
    <svg
      width={width || "20px"}
      height={height || "12px"}
      style={{ marginLeft: marginLeft }}
      className={className || "svg-child-polygon-fill-secondary"}
      viewBox="0 0 20 12"
    >
      <g
        id="Icons-&amp;-Symbols"
        stroke="transparent"
        strokeWidth="0"
        fill="transparent"
        fillRule="evenodd"
      >
        <g
          id="Icons"
          transform="translate(-887.000000, -236.000000)"
          fill="transparent"
          fillRule="nonzero"
        >
          <rect
            fill="transparent"
            x="0"
            y="0"
            width="1500"
            height="1462"
          ></rect>
          <g
            id="icon/arrow/accent"
            transform="translate(887.000000, 232.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <polygon
              id="arrow"
              fillRule="evenodd"
              points="14.1971831 4 14.1971831 8.6556741 0 8.6556741 0 10.7618124 14.1971831 10.7618124 14.1971831 15.4285714 20 9.71428571"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

LinkArrowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  marginLeft: PropTypes.string,
  className: PropTypes.string,
};

export default LinkArrowIcon;
